import request from '@/auth/jwt/request'

/**
 * @description 管理报表 -- 项目要素推广汇总
 * @param {Object} param params {Object} 传值参数
 */
export const reportListProject = params => { return request('report/listProject', 'get', params) }

/**
 * @description 管理报表 -- 项目要素推广汇总详情客户
 * @param {Object} param params {Object} 传值参数
 */
export const reportListProjectInfo = params => { return request('report/listProjectUser', 'get', params) }

/**
 * @description 管理报表 -- 员工推广汇总
 * @param {Object} param params {Object} 传值参数
 */
export const reportListStaff = params => { return request('report/listStaff', 'get', params) }

 /**
 * @description 管理报表 -- 员工推广汇总详情
 * @param {Object} param params {Object} 传值参数
 */
export const reportListStaffInfo = params => { return request('report/listStaffUser', 'get', params) }

/**
 * @description 管理报表 --- 线索维度汇总
 * @param {Object} param params {Object} 传值参数
 */
export const reportListClue = params => { return request('report/listClue', 'get', params) }

/**
 * @description 管理报表 --- 员工下拉
 * @param {Object} param params {Object} 传值参数
 */
export const reportStaffList = params => { return request('pull-down/staff', 'get', params) }

/**
 * @description 管理报表 --- 项目下拉
 * @param {Object} param params {Object} 传值参数
 */
 export const reportProjectList = params => { return request('pull-down/projectSelect', 'get', params) }
